<template>
  <v-row justify="center">
    <v-dialog v-model="show" width="800px" scrollable persistent>
      <v-card>
        <v-card-title>{{$t("consent.setting_history")}}</v-card-title>          
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <!-- <div v-html='data.policy.policy_detail'></div> -->
          <v-layout justify-center align-center>
            <!-- <v-card-subtitle :style="headerTable">{{$t("consent.current_status")}} {{name_current_status === '0' ? $t("consent.receive_all_with_except") : name_current_status === '1' ? $t("consent.receive_specificate") : $t("consent.receive_all") }}</v-card-subtitle> -->
            <v-card-subtitle :style="headerTable"> {{$t("consent.current_status")}} </v-card-subtitle>
            <h3 v-if="name_current_status === '0'" style="color:#C51D1D"> {{$t("consent.receive_all_with_except")}} </h3>
            <h3 v-else-if="name_current_status === '1'" style="color:#E5D426"> {{$t("consent.receive_specificate")}} </h3>
            <h3 v-else-if="name_current_status === '2'" style="color:#338300"> {{$t("consent.receive_all")}} </h3>
            <v-list-item-action class="darkmode">
              <!-- <v-switch
                inset
                :color="color.theme"
                v-model="data.status"
              ></v-switch> -->
            </v-list-item-action>
          </v-layout>
                    <div v-if="resolutionScreen >= 400">
                      <v-data-table
                        :headers="header"
                        :items="history_log"
                        class="elevation-0"
                        :no-data-text="$t('tablefile.empty_resend')"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                        <!-- <template v-slot:[`header.type`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template> -->
                        <template v-slot:[`header.name`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <template v-slot:[`header.dtm`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <template v-slot:[`header.status`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>

                        <template v-slot:item="props">
                          <!-- log ข้อมูลการใช้งาน จำกัดการรับ e-tax -->
                          <tr class="pointer" style="cursor:pointer;" @click="consent_etax_log(props.item)">
                            <!-- <td width="7%" class="text-center">
                              <v-icon>mdi-numeric-1</v-icon>
                            </td> -->
                             <!-- <td width="60%">รับทั้งหมด</td>
                            <td width="30%">วัน/เดือน/ปี</td> -->
                            <td v-if="$t('default') === 'th'" class="text-center" width="33%">{{ props.item.name_th === null ? "ระบบ" : props.item.name_th}}</td>
                            <td v-else class="text-center" width="33%">{{ props.item.name_eng === null ? "System" : props.item.name_eng}}</td>
                            <td class="text-center" width="33%">{{ formatdatetime(props.item.process_dtm)}}</td>
                            <td class="text-center" width="33%">{{ props.item.status_accept === '0' ? $t("consent.receive_all_with_except") : props.item.status_accept === '1' ? $t("consent.receive_specificate") : $t("consent.receive_all")}}</td>
                          </tr>
                          <!-- <tr class="pointer" style="cursor:pointer;">
                            <td width="7%" class="text-center">
                              <v-icon>mdi-numeric-2</v-icon>
                            </td>
                            <td width="60%">รับเฉพาะ</td>
                            <td width="30%">วัน/เดือน/ปี</td>
                          </tr>
                          <tr class="pointer" style="cursor:pointer;">
                            <td width="7%" class="text-center">
                              <v-icon>mdi-numeric-3</v-icon>
                            </td>
                            <td width="60%">ยกเว้น</td>
                            <td width="30%">วัน/เดือน/ปี</td>
                          </tr> -->
                        </template>
                      </v-data-table>
                    </div>
                    <div v-else>
                      <v-card :style="displayMobile">
                      <v-list dense two-line class="mb-0 pb-0 elevation-0" v-if="history_log.length !== 0" :style="styleDisplayMobile">
                        <v-list-item v-for="item in history_log" :key="item.title" style="cursor: pointer" :style="displayMobileForListItem">
                          <v-list-item-content>
                            <v-list-item-title style="line-height:22px;" v-text="item.status_accept === '0' ? $t('consent.receive_all_with_except') : item.status_accept === '1' ? $t('consent.receive_specificate') : $t('consent.receive_all')"></v-list-item-title>
                            <!-- <v-list-item-subtitle v-text="item.name_th === null ? '-' : item.name_th"></v-list-item-subtitle> -->
                            <v-list-item-subtitle v-text="formatdatetime(item.process_dtm)"></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action style="align-self: center;">
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              @click="consent_etax_log(item)"
                            >
                              <v-icon>mdi-information</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-list v-else class="mb-6 pb-6 elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      </v-card>
                    </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('close')">{{ $t("consent.close") }}</v-btn>
          <!-- <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="submitConsent()"
          >
            {{ $t("myinboxPage.settingHeader.submit") }}
          </v-btn> -->
        </v-card-actions>
        <dialogconsentetaxlog
        :data="list_taxid"
        :show="opendialogconsentetaxlog"
        @close="opendialogconsentetaxlog = false"
        >
        </dialogconsentetaxlog>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { required, maxLength, email } from "vuelidate/lib/validators";
const dialogconsentetaxlog = () => import("../optional/dialog-consent-etax-log.vue")
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
    props: ["show"],
    components:{
      dialogconsentetaxlog
    },
     data() {
    return {
      opendialogconsentetaxlog: false,
      history_log:[],
      name_current_status:"",
      listconsent_etax_log:[],
      listconsent:[],
      list_taxid:{},
      size: 20,
      page: 1,
      listsize: [10, 20, 50, 100],
      header: [
        // {
        //   text: "#",
        //   align: "center",
        //   value: "type",
        //   width: "7%",
        //   sortable: false,
        // },
        {
          text: "consent.name_user",
          align: "center",
          value: "name",
          width: "33%",
          sortable: false,
        },       
        {
          text: "consent.modified_date",
          align: "center",
          value: "dtm",
          width: "33%",
          sortable: false,
        },
        {
          text: "consent.Status",
          align: "center",
          value: "status",
          width: "33%",
          sortable: false,
        },
        // {
        //   text: "admin.systemconsent",
        //   align: "",
        //   value: "consent_header",
        //   width: "80%",
        //   sortable: false,
        // },        
        // {
        //   text: "admin.status",
        //   align: "",
        //   value: "status",
        //   width: "13%",
        //   sortable: false,
        // },        
      ],
        }
     },
     computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.listconsent.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.listconsent.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          console.log("show",this.show);
        //   this.get_log_consent_etax();
        }
        return this.show
      }
    }
},
methods: {
  formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute 
          // +
          // ":" +
          // second
        );
      }
    },
    async get_log_consent_etax(){
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v1/get_accept_inbox_log", payload, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
          if (res.data.status == "OK") {
            console.log("เข้า get_log_consent_etax", res.data);            
             this.listconsent_etax_log = res.data;
             console.log("listconsent_etax_log",this.listconsent_etax_log);
             this.name_current_status = res.data.current_status;
             this.history_log = res.data.history_log;
             console.log("history_log",this.history_log);
            //  for(let i = 0; i<history_log.length; i++){
            //     this.history_log = this.history_log[i]
            //     console.log("history_log",this.history_log);
            //  }
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loading = false;
        });

    },
    consent_etax_log(item){
      this.opendialogconsentetaxlog = true;
      console.log("open log etax");
      this.list_taxid = item;
    },
    // async getConsent() {
    //   let payload = {
    //     account_id: this.dataAccountId,
    //     business_id: this.dataAccountActive.business_info.business_id,
    //     one_biz_id: this.dataAccountActive.business_info.id,
    //   };
    //   console.log("payload checkStatusConsent", payload);
    //   let auth = await gbfGenerate.generateToken();
    //   await this.axios
    //     .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/get_accept_system", payload, {
    //       headers: { Authorization: auth.code },}
    //     )
    //     .then((res) => {
    //       if (res.data.status == "OK") {
    //         console.log("เข้านี่จ้าา", res.data);            
    //         this.listconsent = res.data.data;
    //       } else {
    //         Toast.fire({
    //           icon: "error",
    //           title: this.$t("toast.cannotconnectonebox"),
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       Toast.fire({
    //         icon: "error",
    //         title: this.$t("toast.cannotconnectonebox"),
    //       });
    //       this.loading = false;
    //     });
    // },

},
mounted() {
    // this.getConsent();
    this.get_log_consent_etax();
    // this.loaddataprogress = false;
  },

}
</script>

<style>

</style>